/* You can add global styles to this file, and also import other style files */
@import './assets//flags/flags.css';

* {
    box-sizing: border-box;
}

:root {
    font-size: 1rem;
    --font-family: "myFont";
    --color-primary: #000000;
    --color-header: white;
    --color-background: #f5f6f7;
    --error-bold: #C10D17;
    --error-soft: #FEE8E3;
    --warning-bold: #CC8928;
    --success-bold: #22C55E;
    --success-soft: #b2d788;
    --gray-bold: #273c48fa;
    --gray-soft: #273c48a8;
    --outline-gray: #e5e7e8;
    --outline-dashed-gray: #d8dadb;
}

html,
body {
    height: 100%;
    font-family: var(--font-family) !important;
    color: var(--color-primary);
}

header {
    background-color: white;
}

.page-container {
    height: calc(100% - 30px) !important;
}

.page-container-menu {
    height: calc(100% - 110px) !important;
}

body {
    margin: 0;
    font-size: 14px;
    line-height: 25px;
    overflow-y: hidden;
}

a {
    text-decoration: underline;
    cursor: pointer;
}

h1,
.mat-typography h1 {
    font-family: var(--font-family);
    font-size: 20px;
    font-weight: bold;
    margin: 0;
    text-align: center;
    color: var(--color-primary)
}

h2,
.mat-typography h2 {
    font-family: var(--font-family);
    font-size: 20px;
    font-weight: bold;
    margin: 0;
    color: var(--color-primary)
}

h3 {
    font-size: 16px;
    font-weight: bold;
}

button {
    outline: none;
}

button,
select {
    cursor: pointer;
    color: var(--color-primary);
}

a {
    color: var(--color-primary);
}

.background-color {
    background-color: var(--color-background);
}

.color-white {
    color: white;
}

.color-black {
    color: black;
}

.cursor-pointer {
    cursor: pointer;
}

.hidden {
    display: none !important;
}

.visibility {
    visibility: hidden;
}

.w-full {
    width: 100%;
}

.w-min {
    width: min-content;
}

.w-min-10-percent {
    min-width: 10%;
}

.w-30-percent {
    width: 30%;
}

.w-40-percent {
    width: 40%;
}

.w-50-percent {
    width: 50%;
}

.w-60-percent {
    width: 60%;
}

.w-85-percent {
    width: 85%;
}

.w-max-50-percent {
    max-width: 50%;
}

.h-full {
    height: 100%;
}

.h-min {
    height: min-content;
}

.h-fit {
    height: fit-content;
}

.w-auto {
    width: auto !important;
}

.h-auto {
    height: auto !important;
}

.height-24 {
    height: 24px;
}

.min-h-auto {
    min-height: auto !important;
}

.min-h-screen {
    min-height: 100vh;
}

.h-85-percent {
    height: 85%;
}

.opacity-0 {
    opacity: 0 !important;
}

.opacity-100 {
    opacity: 1 !important;
}

.flex {
    display: flex;
}

.grid {
    display: grid;
}

.grid-4-columns {
    grid-template-columns: repeat(4, 1fr);
}

.block {
    display: block;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-column {
    flex-direction: column;
}

.flex-row {
    flex-direction: row;
}

.justify-center {
    justify-content: center !important;
}

.justify-start {
    justify-content: flex-start;
}

.justify-end {
    justify-content: flex-end;
}

.justify-between {
    justify-content: space-between
}

.align-center {
    align-items: center;
}

.align-end {
    align-items: flex-end;
}

.text-center {
    text-align: center;
}

.text-end {
    text-align: end;
}

.text-underline {
    text-decoration: underline;
}

.m--5 {
    margin: .5rem;
}

.mr-0 {
    margin-right: 0 !important;
}

.ml--5 {
    margin-left: .5rem;
}

.ml-1 {
    margin-left: 1rem;
}

.ml-2 {
    margin-left: 2rem;
}

.mt--5 {
    margin-top: .5rem;
}

.mt-1 {
    margin-top: 1rem;
}

.mb--5 {
    margin-bottom: .5rem;
}

.mb--1-25 {
    margin-bottom: 1.25rem;
}

.mb-2 {
    margin-bottom: 2rem;
}

.mb-3 {
    margin-bottom: 3rem;
}

.p--5 {
    padding: .5rem;
}

.p-1 {
    padding: 1rem;
}

.px-1 {
    padding: 0 1rem !important;
}

.pt-1-25 {
    padding-top: 1.25rem;
}

.pt-1-5 {
    padding-top: 1.5rem !important;
}

.l-0 {
    left: 0;
}

.r-0 {
    right: 0;
}

.t-0 {
    top: 0;
}

.b-0 {
    bottom: 0;
}

.gap-0 {
    gap: 0;
}

.gap--3 {
    gap: .3rem;
}

.gap--5 {
    gap: .5rem;
}

.gap--8 {
    gap: .8rem;
}

.gap-1 {
    gap: 1rem;
}

.gap-2 {
    gap: 2rem;
}

.row-gap-1 {
    row-gap: 1rem;
}

.relative {
    position: relative;
}

.fixed {
    position: fixed;
}

.absolute {
    position: absolute;
}

.inset-0 {
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.font-family {
    font-family: var(--font-family);
}

.font-inherit {
    font-family: inherit;
}

.font-20 {
    font-size: 20px;
}

.font-25 {
    font-size: 25px;
}

.font-bold {
    font-weight: bold;
}

.border {
    border: 1px solid var(--color-primary);
}

.border-radius-50-percent {
    border-radius: 50%;
}

.white-space-nowrap {
    white-space: nowrap;
}

.overflow-auto {
    overflow: auto;
}

.overflow-y-auto {
    overflow-y: auto;
}

.overflow-y-scroll {
    overflow-y: scroll;
    scrollbar-width: none;
}

.overflow-y-scroll::-webkit-scrollbar {
    display: none;
}

@media (max-width: 992px) {
    .lg\:hidden {
        display: none !important;
    }

    .lg\:w-full {
        width: 100% !important;
    }

    .lg\:w-90-percent {
        width: 90% !important;
    }

    .lg\:w-60-percent {
        width: 60% !important;
    }

    .lg\:w-50-percent {
        width: 50% !important;
    }

    .lg\:w-40-percent {
        width: 40% !important;
    }

    .lg\:w-max-full {
        max-width: 100% !important;
    }

    .lg\:gap-0 {
        gap: 0 !important
    }

    .lg\:gap-1 {
        gap: 1rem !important;
    }

    .lg\:flex {
        display: flex !important;
    }

    .lg\:block {
        display: block !important;
    }

    .lg\:flex-column {
        flex-direction: column !important;
    }

    .lg\:flex-row {
        flex-direction: row !important;
    }

    .lg\:flex-column-reverse {
        flex-direction: column-reverse;
    }

    .lg\:flex-wrap {
        flex-wrap: wrap !important;
    }

    .lg\:justify-between {
        justify-content: space-between !important;
    }

    .lg\:align-center {
        align-items: center !important;
    }

    .lg\:block-white {
        background-color: var(--color-header);
        padding: 16px;
        border-radius: 16px;
    }
}

@media (max-width: 768px) {

    .md\:hidden {
        display: none !important;
    }

    .md\:w-full {
        width: 100% !important;
    }

    .md\:h-screen {
        height: calc(100% - 30px) !important;
    }

    .md\:min-h-screen {
        min-height: 100vh !important;
    }

    .md\:h-auto {
        height: auto !important;
    }

    .md\:h-full {
        height: 100% !important;
    }

    .md\:w-90-percent {
        width: 90% !important;
    }

    .md\:w-70-percent {
        width: 70% !important;
    }

    .md\:w-30-percent {
        width: 30% !important;
    }

    .md\:relative {
        position: relative !important;
    }

    .md\:flex {
        display: flex !important;
    }

    .md\:flex-row {
        flex-direction: row !important;
    }

    .md\:flex-column {
        flex-direction: column !important;
    }

    .md\:block {
        display: block !important;
    }

    .md\:pl-0 {
        padding-left: 0 !important;
    }

    .md\:pr-0 {
        padding-right: 0 !important;
    }
}

@media (max-width: 450px) {
    .sm\:hidden {
        display: none !important;
    }

    .sm\:w-auto {
        width: auto !important;
    }

    .sm\:w-full {
        width: 100% !important;
    }

    .sm\:h-full {
        height: 100% !important;
    }

    .sm\:gap-0 {
        gap: 0rem !important;
    }

    .sm\:justify-between {
        justify-content: space-between !important;
    }

    .sm\:h-full {
        height: 100% !important;
    }

    .sm\:obj-cover {
        object-fit: cover;
    }

    .sm\:flex-column {
        flex-direction: column !important;
    }

    .sm\:flex-row {
        flex-direction: row !important;
    }
}

.mat-dialog-container {
    position: relative;
    border-radius: .5rem !important;
    overflow: visible !important;
}

label-dialog {
    display: flex;
    flex-direction: column;
    gap: .5rem
}

login-dialog {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.form-input>input {
    width: 100%;
    height: 3rem;
    font-size: 1rem;
    font-family: inherit;
    padding: .5rem;
    border-radius: .5rem;
    border: 1px solid var(--outline-dashed-gray);
    color: var(--color-primary);
}

.ng-invalid-input {
    border-color: var(--error-bold) !important;
}

.bold-featured-text {
    font-size: 16px;
    font-weight: bold;
}

.text-small {
    font-size: 12px;
}

.extra-text {
    font-size: 12px;
    color: var(--gray-soft)
}

.extra-text-invalid {
    font-size: 12px;
    color: var(--error-bold);
}

.invalid-field {
    border: 1px solid var(--error-bold) !important;
}

.block-white {
    background-color: var(--color-header);
    padding: 16px;
    border-radius: 16px;
}

.outline-gray-soft-16 {
    padding: 16px;
    border: 1px solid var(--outline-gray);
    border-radius: 16px;
}

.outline-gray-8 {
    padding: 8px;
    border: 1px solid var(--outline-dashed-gray);
    border-radius: 8px;
}

.outline-dashed-gray {
    border: 1px dashed var(--outline-dashed-gray);
    border-radius: 16px;
}

.timeline {
    min-height: 2px;
    background-color: var(--color-primary);
    z-index: 888;
}

#find-sell-app .previousButton {
    display: none;
}

.item {
    width: 12px;
}

.item .icon {
    width: 12px;
    height: 12px;
    border-radius: 16px;
    background-color: var(--outline-dashed-gray);
}

.item.active .icon {
    border: 2px solid;
    background-color: transparent;
}

.item.before-active .icon,
.separator.before-active {
    background-color: var(--color-primary);
}

.item .text {
    color: var(--gray-soft);
}

.item.before-active .text,
.item.active .text {
    color: var(--color-primary);
}

.text {
    position: absolute;
    top: 20px;
    width: 165px;
    text-align: center;
}

.separator {
    height: 3px;
    width: 300px;
    background-color: rgba(50, 57, 61, 0.15);
    position: relative;
    top: 4px;
}

.line {
    height: 1px;
    background-color: var(--outline-gray);
}

.error-message {
    color: var(--error-bold);
    font-size: .8rem;
    font-weight: 500;
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: .5rem;
    border-radius: .5rem;
    gap: .5rem;
}

.error-message-warning {
    color: var(--warning-bold);
    font-size: .8rem;
    font-weight: 500;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: .5rem;
    border-radius: .5rem;
    gap: .5rem;
}

.button-btn {
    background-color: var(--color-primary);
    border: 1px solid var(--color-primary);
    color: white;
    height: 3rem;
    min-height: 3rem;
    font-size: 1rem;
    font-family: inherit;
    font-weight: bold;
    padding: .5rem;
    border-radius: .5rem;
    cursor: pointer;
}

.button-btn:disabled {
    opacity: 0.5 !important;
    cursor: default;
}

.button-btn-secundary {
    background-color: transparent;
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    height: 3rem;
    min-height: 3rem;
    font-size: 1rem;
    font-family: inherit;
    font-weight: bold;
    padding: .5rem;
    border-radius: .5rem;
    cursor: pointer;
}

.button-btn-small {
    background-color: var(--color-primary);
    border: 1px solid var(--color-primary);
    color: white;
    font-size: 1rem;
    font-family: inherit;
    font-weight: 100;
    padding: 5px 10px;
    border-radius: .5rem;
    cursor: pointer;
}

.p-dialog {
    border-radius: 6px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    border: 0 none;
}

.p-dialog .p-dialog-header {
    border-bottom: 0 none;
    background: #ffffff;
    color: #343a40;
    padding: 1.5rem;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}

.p-dialog .p-dialog-header .p-dialog-title {
    font-weight: 700;
    font-size: 1.25rem;
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
    width: 2rem;
    height: 2rem;
    color: #6c757d;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    margin-right: 0.5rem;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
    color: #343a40;
    border-color: transparent;
    background: #e9ecef;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #C7D2FE;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
    margin-right: 0;
}

.p-dialog .p-dialog-content {
    background: #ffffff;
    color: #495057;
    padding: 0 1.5rem 2rem 1.5rem;
}

.p-dialog .p-dialog-footer {
    border-top: 0 none;
    background: #ffffff;
    color: #495057;
    padding: 0 1.5rem 1.5rem 1.5rem;
    text-align: right;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}

.p-dialog .p-dialog-footer button {
    margin: 0 0.5rem 0 0;
    width: auto;
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
    font-size: 2rem;
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
    margin-left: 1rem;
}

.mat-typography {
    font: var(--font-family);
}

.mat-progress-spinner {
    display: flex !important;
    position: absolute !important;
    width: 20px !important;
    height: 20px !important;
    left: -30px !important;
}

.mat-progress-spinner[mode=indeterminate] svg {
    width: min-content !important;
    height: 100% !important;
    position: relative !important;
}

.mat-progress-spinner circle,
.mat-spinner circle {
    stroke: var(--color-header);
}

.loadProduct .mat-spinner circle {
    stroke: black !important;
}

.loadProduct .mat-progress-spinner {
    position: relative !important;
}

.load-page .mat-progress-spinner circle,
.load-page .mat-spinner circle {
    stroke: rgb(63, 63, 63) !important;
}

.mat-form-field-infix {
    border: 0 !important;
    padding: 0 .5rem !important;
}

.mat-form-field-flex {
    align-items: center !important;
}

.mat-form-field-appearance-outline .mat-form-field-flex {
    padding: .5rem !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
    height: 100%;
    top: 0 !important;
}

.mat-form-field-wrapper {
    padding: 0 !important;
    margin: 0 !important
}

.mat-form-field-label-wrapper {
    display: none;
}

.mat-form-field-appearance-outline .mat-form-field-prefix,
.mat-form-field-appearance-outline .mat-form-field-suffix {
    top: 0 !important
}

.mat-input-element {
    caret-color: black;
}

.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
    color: var(--error-bold) !important;
}

.mat-form-field-invalid .mat-input-element {
    caret-color: var(--error-bold) !important;
}

.mat-form-field-appearance-outline.error .mat-form-field-outline-start,
.mat-form-field-appearance-outline.error .mat-form-field-outline-end {
    border-color: var(--error-bold) !important;
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
    color: var(--color-primary)
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-end,
.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-start {
    border-width: 1px !important;
}

.medium-icon {
    width: 20px !important;
    height: 20px !important;
    font-size: 20px;
}

.upload-image-icon:hover {
    opacity: .8;
}

app-return-menu header .order-details {
    display: none !important;
}

.popUp-block .form-input label {
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    font-size: 12px;
}

.popUp-block .form-input:focus-within label {
    color: black;
    /* font-weight: 600; */
}

.popUp-block .form-input>input {
    height: auto !important;
    padding-top: 1.5rem;
}

.popUp-block .form-input>input:focus {
    outline: none;
    border-color: black;
}

input:focus,
textarea:focus,
select:focus,
mat-form-field:focus {
    outline-style: solid !important;
    outline-width: 1px !important;
    outline-color: var(--color-primary) !important;
    border-color: transparent !important;
    outline-offset: 0 !important;
}

mat-form-field input:focus {
    outline: none !important;
}

.button-input {
    background: none;
    border: none;
}

.select-block {
    height: 42.5px;
    border-radius: 5px;
    border: 1px solid var(--outline-dashed-gray);
    padding: .5rem;
    font-size: 16px;
    background-color: white;
    color: var(--color-primary);
}

.select-block.block-form {
    height: 54px;
    width: 100%;
    padding-top: 1.5rem;
}

.select-block option {
    font-weight: normal;
}

.info-icon {
    width: min-content;
    font-size: 18px;
    display: flex;
    align-items: center;
}

.mat-menu-panel {
    padding: 0 10px;
    min-height: auto !important;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: var(--color-primary);
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: var(--color-primary) !important;
}

.mat-tab-label {
    color: var(--color-primary) !important;
    opacity: 1 !important;
    text-transform: uppercase;
    font-family: inherit;
}

.mat-tab-label {
    color: var(--color-primary) !important;
    opacity: 1 !important;
    text-transform: uppercase;
    font-family: inherit;
}

.mat-tab-label.mat-tab-label-active {
    font-weight: bold;
}

.toogle-normal-button .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 46px !important;
    padding: 0 16px !important;
}

.toogle-small-button .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 28px !important;
    padding: 0 16px !important;
}

.toogle-small-button .mat-button-toggle-checked.mat-button-toggle-appearance-standard,
.toogle-small-button .mat-button-toggle-checked.mat-button-toggle-appearance-standard .mat-button-toggle-button:hover {
    font-weight: 400 !important;
    color: white;
    background-color: var(--color-primary);
    border-radius: 0.5rem;
}

.toogle-small-button .mat-button-toggle-group-appearance-standard .mat-button-toggle+.mat-button-toggle {
    border: none !important;
}

.toogle-small-button .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.toogle-small-button .mat-button-toggle-group-appearance-standard {
    border-radius: .5rem !important;
    border-color: #e0e0e0 !important;
    font-weight: normal !important;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
    border-radius: .5rem !important;
    border-color: black;
    font-weight: bold;
}

.toogle-normal-button .mat-button-toggle-disabled.mat-button-toggle-appearance-standard:hover {
    background-color: white;
    color: rgba(0, 0, 0, 0.26);
}

.toogle-normal-button .mat-button-toggle-appearance-standard:hover {
    background-color: black;
    color: white;
}

.mat-radio-outer-circle,
.mat-radio-inner-circle {
    width: 15px !important;
    height: 15px !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: var(--color-primary);
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    background-color: var(--color-primary);
}

.link-2hand:hover {
    font-weight: 600 !important;
}